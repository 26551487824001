import {Component, Inject, OnInit} from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {Observable, of} from 'rxjs';
import { finalize } from 'rxjs/operators';

import { UsersService } from '../../../services/data/users.service';
import {FIREBASE_REFERENCES} from '../../../../firebase/firebase.module';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AngularFireAuth} from '@angular/fire/auth';


@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Change the Password</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form [formGroup]="passwordForm" (ngSubmit)="submitForm()">
      <div class="modal-boy">
        <div class="container">
          <div class="form-group">
            <label for="oldPassword">Current password</label>
            <input id="oldPassword" type="password" class="form-control" formControlName="oldPassword" placeholder="Write the current password">
            <div *ngIf="passwordForm.controls.oldPassword.touched && passwordForm.controls.oldPassword.errors?.required" class="text text-danger">
              Password is required.
            </div>
            <div *ngIf="passwordForm.controls.oldPassword.touched && passwordForm.controls.oldPassword.errors?.minlength" class="text text-danger">
              The password must to have at least 6 characters.
            </div>
          </div>
          <div class="form-group">
            <label for="newPassword">Password</label>
            <input id="newPassword" type="password" class="form-control" formControlName="newPassword" placeholder="Write your new password">
            <div *ngIf="passwordForm.controls.newPassword.touched && passwordForm.controls.newPassword.errors?.required" class="text text-danger">
              Password is required.
            </div>
            <div *ngIf="passwordForm.controls.newPassword.touched && passwordForm.controls.newPassword.errors?.minlength" class="text text-danger">
              The password must to have at least 6 characters.
            </div>
          </div>
          <div class="form-group">
            <label for="confirmNewPassword">Confirm password</label>
            <input id="confirmNewPassword" type="password" class="form-control" formControlName="confirmNewPassword" placeholder="Confirm your new password">
            <div *ngIf="passwordForm.controls.confirmNewPassword.touched && passwordForm.controls.confirmNewPassword.errors?.required" class="text text-danger">
              It's necessary to confirm the password.
            </div>
            <div *ngIf="passwordForm.controls.confirmNewPassword.touched && passwordForm.controls.confirmNewPassword.errors?.minlength" class="text text-danger">
              The password must to have at least 6 characters.
            </div>
            <div *ngIf="passwordForm.controls.confirmNewPassword.touched && passwordForm.controls.confirmNewPassword.errors?.mismatch" class="text text-danger">
              Passwords don't match.
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-success"
                [disabled]="!passwordForm.valid">
          Change
        </button>
      </div>
    </form>
  `
})
export class NgbdModalContentChangePassword {

  passwordForm: FormGroup;

  constructor(public activeModal: NgbActiveModal,
              private toastrService: ToastrService,
              private formBuilder: FormBuilder,
              @Inject(FIREBASE_REFERENCES.ADMIN_FIREAUTH) private afAuth: AngularFireAuth) {
    this.passwordForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required, Validators.minLength(6)]],
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmNewPassword: ['', [Validators.required,
        Validators.minLength(6),
        (control => this.confirmPassword(control, this.passwordForm, 'newPassword'))]]

    });
  }

  private submitForm() {
    var self = this;
    if (this.passwordForm.status === 'VALID') {
      var email = this.afAuth.auth.currentUser.email;
      var user = this.afAuth.auth.signInWithEmailAndPassword(email, this.passwordForm.controls.oldPassword.value)
        .then(function (user) {
          self.afAuth.auth.currentUser.updatePassword(self.passwordForm.controls.newPassword.value).then(function () {
            self.toastrService.success('Password Changed', 'Success!');
            self.activeModal.close(self.passwordForm.value);
          }).catch(function (err) {
            console.log('Error changing the password' + err);
            self.toastrService.error(err.message, 'Error!');
          });
        }).catch(function (err) {
          console.log('Error sign in with email and password' + err);
          self.toastrService.error('The previous password is incorrect', 'Error!');
        });
    } else {
      this.toastrService.error('The form is invalid', 'Error!');
    }
  }

  confirmPassword(control: FormControl, group: FormGroup, matchPassword: string) {
    if (group!=null && group.controls[matchPassword].value !== null && group.controls[matchPassword].value === control.value) {
      return null;
    }
    return { 'mismatch': true };
  }
} // END Delete Change Password

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})

export class EditUserComponent implements OnInit {

  user: any = {
    logo : ''
  };

  validationForm: FormGroup = this.fb.group({
    displayName: new FormControl(null),
    role: new FormControl(null),
    logo: new FormControl(null),
    previousLogo: new FormControl(null),
    agree: new FormControl(null)
  });
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;

  constructor(private fb: FormBuilder,
              private usersService: UsersService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private toastrService: ToastrService,
              @Inject(FIREBASE_REFERENCES.ADMIN_FIRESTORAGE) private storage: AngularFireStorage,
              private modalService: NgbModal) {
    this.usersService.getUser().subscribe(res => {
      this.user = res;
      this.validationForm = this.fb.group ({
        displayName:  [this.user.displayName, Validators.required],
        role:  [this.user.role],
        logo:  [this.user.logo],
        previousLogo:  [this.user.logo],
        agree:  ['', Validators.required],
      });
    });
  }

  openModalChangePassword() {
    this.modalService.open(NgbdModalContentChangePassword);
  }

  ngOnInit(): void {
  }

  onSubmit() {
    if (this.validationForm.status === 'VALID') {
      this.usersService.updateUser(this.validationForm.value).then( result =>{
        this.toastrService.success('User profile Updated', 'Success!');
      }).catch(err => {
        this.toastrService.error('User can\'t be updated', 'Error!');
        console.log('Error updating the user. ' + err);
      });
      // To clean the upload process
      this.downloadURL = new Observable<string>();
      this.router.navigate(['/user']);
    }
  }

  uploadFile(event) {
    const file = event.target.files[0];
    const filePath = 'users/' + this.usersService.userData.uid + '/image-logo-' + (Math.random().toString(36).substring(7));
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    // observe percentage changes
    this.uploadPercent = task.percentageChanges();
    // get notified when the download URL is available
    task.snapshotChanges().pipe(
      finalize(() =>
        this.updateInputFiles(fileRef)
      )
    ).subscribe();
  }

  private updateInputFiles(fileRef: any) {
    this.downloadURL = fileRef.getDownloadURL();
    this.downloadURL.subscribe(url => {
      if (url){
        this.validationForm.controls.logo.setValue(url);
        // Initialize Percentage
        this.uploadPercent = new Observable<number>();
      }
    });
  }


}

