import {Inject, Injectable} from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import {FIREBASE_REFERENCES} from '../../../firebase/firebase.module';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  user: Observable<any>;
  userData:any;
  constructor(@Inject(FIREBASE_REFERENCES.ADMIN_FIRESTORE) private db: AngularFirestore) {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.user = this.db.collection('users').doc(this.userData.uid).valueChanges();
  }

  getUser() {
    return this.user;
  }

  updateUser(data:any) {
    return this.db.collection('users').doc(this.userData.uid).update({
      email: this.userData.email,
      uid: this.userData.uid,
      displayName: data.displayName,
      role: data.role,
      logo: data.logo
    });
  }



}
