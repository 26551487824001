import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	role?: string;
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any;
	public collapseSidebar: boolean = false;
	public fullScreen = false;
  private userData: any;

  private MENUITEMS: Menu[] = [
    {
      path: '/dashboard', icon: 'home', title: 'Dashboard', type: 'link'
    },
    {
      title: 'Administrator', icon: 'key', type: 'sub', role: 'Administrador', active: false, children: [
        { path: '/distributor/list', title: 'Distributors', type: 'link' },
        { path: '/distributor/new', title: 'Add distributor', type: 'link' }
      ]
    },
    {
      title: 'Companies', icon: 'briefcase', type: 'sub', active: false, children: [
        { path: '/company/list', title: 'My companies', type: 'link' },
        { path: '/company/new', title: 'Add company', type: 'link' }
      ]
    },
    {
      title: 'Clientes', icon: 'watch', type: 'sub', active: false, children: [
        { path: '/clients/list', title: 'My clients', type: 'link' },
        { path: '/clients/new', title: 'Add client', type: 'link' }
      ]
    },
    {
      title: 'Gateways', icon: 'wifi', type: 'sub', active: false, children: [
        { path: '/gateways/list', title: 'My Gateways', type: 'link' },
        { path: '/gateways/new', title: 'Add Gateway', type: 'link' },
      ]
    },
    {
      title: 'Wearables', icon: 'watch', type: 'sub', active: false, children: [
        { path: '/wearables/list', title: 'My Wearables', type: 'link' },
        { path: '/wearables/new', title: 'Add Wearable', type: 'link' }
      ]
    }
  ];
  // Array
  //items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

	constructor() {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true;
		}
    this.userData = JSON.parse(localStorage.getItem('userData'));
  }

	getMenuItems(){
	  const items = this.MENUITEMS.filter(item => {
        return  item?.role === undefined ||
                this.userData.role === 'Administrador' ||
                this.userData.role === item?.role;
    });
	  return new BehaviorSubject<Menu[]>(items);
  }

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}
}
