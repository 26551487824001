// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    'Wardoo' : {
      apiKey: "AIzaSyDcp9BYrKdDtpzfIeffbOHq5IityQRSJD8",
      authDomain: "cobeacon-a7625.firebaseapp.com",
      databaseURL: "https://cobeacon-a7625.firebaseio.com",
      projectId: "cobeacon-a7625",
      storageBucket: "cobeacon-a7625.appspot.com",
      messagingSenderId: "38928089943",
      appId: "1:38928089943:web:aee56a1580b7a8988bf412",
      measurementId: "G-VFRTM6DE0Z"
    },
    'AdminIgual' : {
      apiKey: "AIzaSyDcp9BYrKdDtpzfIeffbOHq5IityQRSJD8",
      authDomain: "cobeacon-a7625.firebaseapp.com",
      databaseURL: "https://cobeacon-a7625.firebaseio.com",
      projectId: "cobeacon-a7625",
      storageBucket: "cobeacon-a7625.appspot.com",
      messagingSenderId: "38928089943",
      appId: "1:38928089943:web:aee56a1580b7a8988bf412",
      measurementId: "G-VFRTM6DE0Z"
    },
    'Admin' : {
      apiKey: "AIzaSyD_29kwQA0S3-NO7jtRbGOMcDT57cr8O40",
      authDomain: "wardoo-master-admin.firebaseapp.com",
      databaseURL: "https://wardoo-master-admin.firebaseio.com",
      projectId: "wardoo-master-admin",
      storageBucket: "wardoo-master-admin.appspot.com",
      messagingSenderId: "434420934397",
      appId: "1:434420934397:web:313817d3ea57c241e44094",
      measurementId: "G-FCM7H2XR3D"
    }

  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
