import {Inject, Injectable} from '@angular/core';
import {AngularFirestore, Query} from '@angular/fire/firestore';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {AuthService, User} from '../../../shared/services/firebase/auth.service';
import {FIREBASE_REFERENCES} from '../../../firebase/firebase.module';
import {CollectionReference, QueryFn} from '@angular/fire/firestore/interfaces';
import {AngularFirestoreCollection} from '@angular/fire/firestore/collection/collection';

export interface QueryOptions {
  distributor: boolean;
  enabled: boolean;
};

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  userData: any;
  spoof: {
    key: string,
    displayName: string
  };
  currentSpoof: BehaviorSubject<any>;

  distributors: Observable<any>;
  constructor(){

    // Store user data.
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.spoof = undefined;
    this.currentSpoof = new BehaviorSubject<any>(undefined);
    // this.currentSpoof.next(undefined);
  }

  isAdmin(): boolean{
    //return false;
    return this.userData.role === 'Administrador' && this.spoof === undefined;
  }

  getSpoof(): BehaviorSubject<any> {
    return this.currentSpoof;
  }

  getDistributorKey(): string{
    if ( this.spoof !== undefined) {
      return this.spoof.key;
    } else {
      return this.userData?.key;
    }
  }

  addDistributorCondition(collection: CollectionReference, opts: QueryOptions): Query {
    let result = undefined;
    if (!this.isAdmin()) {
      if (opts.distributor) {
        result = collection.where('distributor.key', '==', this.getDistributorKey() || '');
      }
    }
    if (opts.enabled) {
      result = (result || collection).where('disabled', '==', false);
    }
    return result;
  }

  startSpoofing(key:string, displayName:string) {
    this.spoof = {
      key: key,
      displayName: displayName
    };
    this.currentSpoof.next(this.spoof);

  }

  stopSpoofing(){
    this.spoof = undefined;
    this.currentSpoof.next(undefined);

  }

}
