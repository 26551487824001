import {InjectionToken, NgModule, NgZone, PLATFORM_ID} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ENV, ENV_CONFIG} from '../core/env';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireAuth} from '@angular/fire/auth';
// import {AngularFire} from '@angular/fire';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import {AuthService} from '../shared/services/firebase/auth.service';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {ToastrService} from 'ngx-toastr';
import {AngularFireFunctions} from '@angular/fire/functions';

export function AngularFirestoreFactory(platformId: any, zone: NgZone, env: ENV, projectId: string) {
  return new AngularFirestore(env.firebase[projectId], projectId, false, null, platformId, zone, null);
}

export function AngularFireAuthFactory(platformId: any, zone: NgZone, env: ENV, projectId: string) {
  return new AngularFireAuth(env.firebase[projectId], projectId, platformId, zone);
}

export function AngularFireFunctionFactory(platformId: any, zone: NgZone, env: ENV, projectId: string) {
  return new AngularFireFunctions(env.firebase[projectId], projectId, platformId, zone, 'us-central1', null);
}

/*export function AngularFireFactory(platformId: any, zone: NgZone, env: ENV, projectId: string) {
  return new AngularFire(env.firebase[projectId], projectId, false, null, platformId, zone, null);
}*/

export function AngularFireDatabaseFactory(platformId: any, zone: NgZone, env: ENV, projectId: string) {
  return new AngularFireDatabase(env.firebase[projectId], projectId, null, platformId, zone);
}

export function AngularFireStorageFactory(platformId: any, zone: NgZone, env: ENV, projectId: string) {
  return new AngularFireStorage(env.firebase[projectId], projectId, null, platformId, zone);
}

export function AuthServiceFactory(platformId: any, zone: NgZone, env: ENV, projectId: string, router: Router, toster:any, cookieService: CookieService) {
  return new AuthService(
    AngularFirestoreFactory(platformId, zone, env, projectId),
    AngularFireAuthFactory(platformId, zone, env, projectId),
    router,
    zone,
    toster,
    cookieService,
    projectId
  );
}

export const FIREBASE_WARDOO = new InjectionToken('firebase project injector');
export const FIREBASE_ADMIN = new InjectionToken('firebase project injector');

export const FIREBASE_REFERENCES = {
  WARDOO_FIRESTORE: 'project-wardoo-firestore',
  ADMIN_FIRESTORE: 'project-admin-firestore',
  WARDOO_FIREAUTH: 'project-wardoo-fireauth',
  ADMIN_FIREAUTH: 'project-admin-fireauth',
  //WARDOO_FIRE: 'project-wardoo-fire',
  //ADMIN_FIRE: 'project-admin-fire',
  WARDOO_FIREDATABASE: 'project-wardoo-firedatabase',
  ADMIN_FIREDATABASE: 'project-admin-firedatabase',
  ADMIN_FIREFUNCTION: 'project-admin-firefunction',
  WARDOO_FIREFUNCTION: 'project-wardoo-firefunction',
  WARDOO_FIRESTORAGE: 'project-wardoo-firestorage',
  ADMIN_FIRESTORAGE: 'project-admin-firestorage',
  WARDOO_AUTHSERVICE: 'project-wardoo-authservice',
  ADMIN_AUTHSERVICE: 'project-admin-authservice',
};

@NgModule({
  declarations: [],
  providers: [
    {provide: FIREBASE_WARDOO, useValue: 'Wardoo'},
    {provide: FIREBASE_ADMIN, useValue: 'Admin'},
    {
      provide: FIREBASE_REFERENCES.WARDOO_FIRESTORE,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_WARDOO],
      useFactory: AngularFirestoreFactory
    },
    {
      provide: FIREBASE_REFERENCES.ADMIN_FIRESTORE,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_ADMIN],
      useFactory: AngularFirestoreFactory
    },
    {
      provide: FIREBASE_REFERENCES.WARDOO_FIREAUTH,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_WARDOO],
      useFactory: AngularFireAuthFactory
    },
    {
      provide: FIREBASE_REFERENCES.ADMIN_FIREAUTH,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_ADMIN],
      useFactory: AngularFireAuthFactory
    },
    {
      provide: FIREBASE_REFERENCES.ADMIN_FIREFUNCTION,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_ADMIN],
      useFactory: AngularFireFunctionFactory
    },
    {
      provide: FIREBASE_REFERENCES.WARDOO_FIREFUNCTION,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_ADMIN],
      useFactory: AngularFireFunctionFactory
    },
    /*{
      provide: FIREBASE_REFERENCES.WARDOO_FIRE,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_WARDOO],
      useFactory: AngularFireFactory
    },
    {
      provide: FIREBASE_REFERENCES.ADMIN_FIRE,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_ADMIN],
      useFactory: AngularFireFactory
    },*/
    {
      provide: FIREBASE_REFERENCES.WARDOO_FIREDATABASE,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_WARDOO],
      useFactory: AngularFireDatabaseFactory
    },
    {
      provide: FIREBASE_REFERENCES.ADMIN_FIREDATABASE,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_ADMIN],
      useFactory: AngularFireDatabaseFactory
    },
    {
      provide: FIREBASE_REFERENCES.WARDOO_FIRESTORAGE,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_WARDOO],
      useFactory: AngularFireStorageFactory
    },
    {
      provide: FIREBASE_REFERENCES.ADMIN_FIRESTORAGE,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_ADMIN],
      useFactory: AngularFireStorageFactory
    },
    {
      provide: FIREBASE_REFERENCES.WARDOO_AUTHSERVICE,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_WARDOO, Router, ToastrService, CookieService],
      useFactory: AuthServiceFactory
    },
    {
      provide: FIREBASE_REFERENCES.ADMIN_AUTHSERVICE,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_ADMIN, Router, ToastrService, CookieService],
      useFactory: AuthServiceFactory
    }
  ],
  imports: [
    CommonModule
  ]
})
export class FirebaseModule {
}
