import { Injectable, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { variable } from '@angular/compiler/src/output/output_ast';

export interface User {
  uid: string;
  email: string;
  company?: string;
  displayName: string;
  role: string;
  logo: string;
  emailVerified: boolean;
  distributor?: {
    key: string;
  };
  address?: {
    country?: string;
    street?: string;
    phone?: string;
  };
  disabled: boolean;
  key?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {

  public userData: any;
  public userParams: User;
  private _sessionId: string;
  public showLoader: boolean = false;
  private wardooUserLogged = false;

  constructor(public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    public toster: ToastrService,
    private cookieService: CookieService,
    private projectId: string) {

    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        this._sessionId = user.refreshToken;
        cookieService.set('user', JSON.stringify(user));
        localStorage.setItem('user', JSON.stringify(user));
      } else {
        localStorage.setItem('user', null);
      }
    });

    if (projectId === 'Wardoo' && this.wardooUserLogged === false){
      // TODO REVIEW THE WARDOO login signInAnonymously or create account for that.
      // this.afAuth.auth.signInWithEmailAndPassword('pabloms@gmail.com', 'wardoo_2020')
      this.afAuth.auth.signInAnonymously()
        .then((result) => {
          // console.log('Logged in wardoo client');
          this.wardooUserLogged = true;
        }).catch((error) => {
          console.log('Impossible to log in wardoo client');
        });
    }
  }

  ngOnInit(): void { }

  //sign in function
  SignIn(email, password) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        if (result.user.emailVerified !== true) {
          this.SetUserData(result.user);
          this.SendVerificationMail();
          this.showLoader = true;
        } else {
          this.SetUserData(result.user);
          this.showLoader = false;
          this.ngZone.run(() => {
            //window.location.replace(window.location.origin);
            //this.router.navigate(['/dashboard']);
          });
        }

      }).catch((error) => {
        this.toster.error('You have enter Wrong Email or Password.');
      })
  }
  //main verification function
  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification()
      .then(() => {
        //this.router.navigateByUrl('/dashboard');
        window.location.replace(window.location.origin);
      })
  }

  //Sign in with Facebook
  signInFacebok() {
    return this.AuthLogin(new auth.FacebookAuthProvider());
  }

  //Sign in with Twitter
  signInTwitter() {
    return this.AuthLogin(new auth.TwitterAuthProvider());
  }

  //Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  ForgotPassword(passwordResetEmail) {
    return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      }).catch((error) => {
        window.alert(error);
      });
  }

  //Authentication for Login
  AuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          //this.router.navigate(['/dashboard']);
          //window.location.replace(window.location.origin);
        });
        this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error);
      });
  }

  //Set user
  SetUserData(user) {

    localStorage.setItem('user', JSON.stringify(user));

    this.getUser(user).subscribe(data => {
      var companyLocal:string = "";
      var logoLocal:string = 'assets/images/dashboard/boy-2.png';
      var roleLocal:string = 'Distribuidor';
      var displayNameLocal:string = 'Default Name';
      var key:string = '';
      var dataObj:any = data;

      if (dataObj) {
        companyLocal = dataObj.company; // console.log(dataObj.company);
        logoLocal = dataObj.logo;
        roleLocal = dataObj.role;
        displayNameLocal = dataObj.displayName;
        key = dataObj.key;
      }

      const userRef: AngularFirestoreDocument<any> = this.afs.doc('users/' + user.uid);
      const userData: User = {
        email: user.email,
        displayName: displayNameLocal,
        company: companyLocal,
        uid: user.uid,
        role: roleLocal,
        logo: logoLocal || 'assets/images/dashboard/boy-2.png',
        emailVerified: user.emailVerified,
        key: key,
        disabled: false
      };

      localStorage.setItem('userData', JSON.stringify(userData));
      this.userParams = userData;

      userRef.set(userData, {
        merge: true
      }).then((result) => {
        this.ngZone.run(() => {
          //this.router.navigate(['/dashboard']);
          window.location.replace(window.location.origin);
        });
      }).catch((error) => {
        window.alert(error);
      });

    });

  }

  getUser(user:any) {
    let userData = this.afs.doc('users/' + user.uid).valueChanges();
    return userData;
  }

  // Sign out
  SignOut() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    return this.afAuth.auth.signOut().then(() => {
      this.showLoader = false;
      localStorage.clear();
      this.cookieService.deleteAll('user', '/auth/login');
      //this.router.navigate(['/auth/login']);
      window.location.replace(window.location.origin);
    });
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user != null && user.emailVerified != false) ? true : false;
  }

}
