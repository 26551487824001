import {Inject, Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from '../services/firebase/auth.service';
import {FIREBASE_REFERENCES} from '../../firebase/firebase.module';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(public router: Router) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Guard for user is login or not
    const user = JSON.parse(localStorage.getItem('userData'));
    if (!user) {
      this.router.navigate(['/auth/login']);
      return false;
    }
    const roles = next.data?.roles;
    if (roles !== undefined && !roles.includes(user.role)) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
